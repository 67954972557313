import React from 'react'
import styled, { css } from 'styled-components'
import to from 'await-catch'
import moment from 'moment'
import Layout from './shared/Layout'
import {
  SENT,
  DELIVERY_STATUS_FILTERS,
  PAGINATION_PARAMETERS,
  REPORT_PERMIT,
  INVOICE_ACTION_SEND,
  INVOICE_ACTION_SEND_ALL,
  INVOICE_ACTION_CANCEL,
  INVOICE_ACTION_CANCEL_ALL,
  CLINICS_ID
} from '../constants'
import Table from './Table'
import { MobileFullWidth, MaterialIcon, Button } from '../styled/generics'
import withUser from '../hoc/withUser'
import {
  getInvoiceList,
  downloadInvoiceList,
  generateInvoicePackage,
  getDownloadLink,
  sendInvoice,
  cancelInvoiceSending,
  getClinicsDetailed,
  getInstitutionsList
} from '../api'
import FormFilterInvoices from './FormFilterInvoices'
import ModalConfirmation from './ModalConfirmation'
import ModalMessage from './ModalMessage'
import { Link } from 'react-router-dom'

class InvoiceList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      invoices: [],
      listOfClinics: [],
      listOfInstitutions: [],
      institution: '',
      submitting: false,
      request: {
        cuit: null,
        tipoComprobante: '',
        tipoFactura: '',
        centroFactura: '',
        numeroComprobante: '',
        idClinica: '',
        estadoEnvio: null,
        fechaDesde: moment()
          .subtract(3, 'months')
          .set('date', 1),
        fechaHasta: moment(),
        items: [],
        pagina: PAGINATION_PARAMETERS.pagina,
        itemsPorPagina: PAGINATION_PARAMETERS.itemsPorPagina,
        totalPaginas: PAGINATION_PARAMETERS.totalPaginas
      },
      filters: [],
      searchedAndNoData: false,
      invoicesSearched: false,
      companiesCuits: [],
      showConfirmModal: false,
      showSuccessModal: false,
      toSendInvoice: {},
      invoiceAction: '',
      confirmFunc: {}
    }

    this.selectedItems = []
  }

  async componentDidMount() {
    if (!!this.props.location.data) {
      this.setState({
        institution: this.props.location.data.searchInvoiceInputs.cuit,
        request: {
          ...this.state.request,
          pagina: this.props.location.data.invoicePage,
          idClinica: this.props.location.data.searchInvoiceInputs.idClinica,
          tipoComprobante: this.props.location.data.searchInvoiceInputs.tipoComprobante,
          tipoFactura: this.props.location.data.searchInvoiceInputs.tipoFactura,
          centroFactura: this.props.location.data.searchInvoiceInputs.centroFactura,
          numeroComprobante: this.props.location.data.searchInvoiceInputs.numeroComprobante,
          fechaDesde: this.props.location.data.searchInvoiceInputs.fechaDesde,
          fechaHasta: this.props.location.data.searchInvoiceInputs.fechaHasta,
          estadoEnvio: this.props.location.data.searchInvoiceInputs.estadoEnvio
        }
      })
    }

    const [errClinics, { data: dataClinics } = {}] = await to(getClinicsDetailed())
    if (!errClinics) {
      const clinicsList = [{ id: 0, nombre: 'Ver todas las clínicas' }].concat(
        dataClinics.filter(clinic => clinic.id !== CLINICS_ID.CentroBazte)
      )
      this.setState({ listOfClinics: clinicsList })
    }

    if (this.props.auth.permisos.some(permiso => permiso.clave === REPORT_PERMIT)) {
      // Reporting
      const [errInstitutions, { data: dataInstitutions } = {}] = await to(getInstitutionsList())
      if (!errInstitutions) {
        this.setState({
          listOfInstitutions: dataInstitutions
        })
      }

      this.setState(
        {
          request: {
            ...this.state.request,
            estadoEnvio: DELIVERY_STATUS_FILTERS.map(item => item.key)
          }
        },
        this.handleSubmit
      )
    } else {
      // Financiador
      this.setState(
        {
          request: {
            ...this.state.request,
            cuit: this.props.auth.metadatos.find(metadata => metadata.metadato.clave === 'CUIT')
              .valor,
            estadoEnvio: DELIVERY_STATUS_FILTERS.filter(item => item.key === SENT).map(
              item => item.key
            )
          }
        },
        this.handleSubmit
      )
    }
  }

  handleSubmit = async (formValues = undefined) => {
    // Preparo form para envío
    let form = {}
    if (!!formValues) {
      form = {
        ...this.state.request,
        cuit:
          this.state.listOfInstitutions.length > 0
            ? formValues.institution
            : this.state.request.cuit,
        tipoComprobante: formValues.tipoComprobante,
        tipoFactura: formValues.tipoFactura,
        centroFactura: formValues.centroFactura,
        numeroComprobante: formValues.numeroComprobante,
        idClinica: formValues.clinic,
        estadoEnvio: formValues.sentStatus,
        fechaDesde: formValues.startDate,
        fechaHasta: formValues.endDate,
        items: []
      }
    } else {
      form = {
        ...this.state.request
      }
    }

    this.setState({
      submitting: true
    })

    // Obtengo Datos del Back
    const [err, { data } = {}] = await to(getInvoiceList(form))
    if (!err) {
      this.setState({
        invoicesSearched: true
      })

      if (data.hasOwnProperty('items')) {
        if (!data || !data.items || data.items.length === 0) {
          this.setState({ searchedAndNoData: true })
        } else {
          this.setState({
            request: { ...form, ...data },
            searchedAndNoData: false
          })
        }
      }
    }
    this.setState({
      submitting: false
    })
  }

  handlePagination = clickEvent => {
    const pageSelected = clickEvent.selected + 1

    this.setState(
      {
        request: {
          ...this.state.request,
          pagina: pageSelected,
          items: []
        }
      },
      this.handleSubmit
    )
  }

  handleSort = (filter, order) => {
    this.setState(
      {
        request: {
          ...this.state.request,
          filtro: filter,
          orden: order,
          pagina: 1,
          items: []
        }
      },
      this.handleSubmit
    )
  }

  downloadInvoiceList = async () => {
    const request = {
      cuit: this.state.request.cuit,
      fechaDesde: this.state.request.fechaDesde,
      fechaHasta: this.state.request.fechaHasta,
      estadoEnvio: this.state.request.estadoEnvio,
      tipoComprobante: this.state.request.tipoComprobante,
      tipoFactura: this.state.request.tipoFactura,
      centroFactura: this.state.request.centroFactura,
      numeroComprobante: this.state.request.numeroComprobante,
      idClinica: this.state.request.idClinica
    }

    const [err, { data } = {}] = await to(downloadInvoiceList(request))
    if (!err) {
      const blob = new Blob([data])
      const filename = `listado_comprobantes_${new Date().getTime()}.xls`

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, filename)
      } else {
        var link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = filename
        link.click()
      }
    }
  }

  downloadInvoicePackage = async (e, row) => {
    const request = {
      centroFactura: row.centroFactura,
      modalidad: row.modalidad,
      idClinica: row.idClinica,
      numeroComprobante: row.numeroComprobante,
      tipoComprobante: row.tipoComprobante,
      tipoFactura: row.tipoFactura,
      idObraSocial: row.idFinanciador,
      cuit: this.state.request.cuit,
      fecha: row.fecha,
      origen: row.origen
    }

    const [err, { data } = {}] = await to(generateInvoicePackage(request))
    if (!err) {
      if (
        request.cuit &&
        !this.props.auth.permisos.some(permiso => permiso.clave === REPORT_PERMIT)
      ) {
        row.descargado = true
        this.forceUpdate()
      }

      var downloadUrl = getDownloadLink(data.token, data.file)
      const link = document.createElement('a')
      link.href = downloadUrl
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }

  toggleConfirmModal = () => this.setState({ showConfirmModal: !this.state.showConfirmModal })

  toggleSuccessModal = () => this.setState({ showSuccessModal: !this.state.showSuccessModal })

  getActionFunc = () => {
    switch (this.state.invoiceAction) {
      case INVOICE_ACTION_SEND:
        return this.sendInvoice
      case INVOICE_ACTION_SEND_ALL:
        return this.sendInvoice
      case INVOICE_ACTION_CANCEL:
        return this.cancelInvoiceSending
      case INVOICE_ACTION_CANCEL_ALL:
        return this.cancelInvoiceSending
      default:
        return () => console.log('Metodo no especificado.')
    }
  }

  getConfirmMessage = () => {
    switch (this.state.invoiceAction) {
      case INVOICE_ACTION_SEND:
        return '¿Desea enviar la facturación ' + this.state.toSendInvoice.numeroFactura + '?'
      case INVOICE_ACTION_SEND_ALL:
        return '¿Está seguro de querer enviar todas las facturaciones?'
      case INVOICE_ACTION_CANCEL:
        return (
          '¿Desea cancelar el envio de la facturación ' +
          this.state.toSendInvoice.numeroFactura +
          '?'
        )
        case INVOICE_ACTION_CANCEL_ALL:
          return '¿Está seguro de querer cancelar todas las facturaciones seleccionadas?'
      default:
        return ''
    }
  }

  getSuccessMessage = () => {
    switch (this.state.invoiceAction) {
      case INVOICE_ACTION_SEND:
        return (
          'La facturación ' + this.state.toSendInvoice.numeroFactura + ' fue enviada correctamente.'
        )
      case INVOICE_ACTION_SEND_ALL:
        return 'La facturaciones fueron enviadas correctamente.'
      case INVOICE_ACTION_CANCEL:
        return (
          'El envio de la facturación ' +
          this.state.toSendInvoice.numeroFactura +
          ' fue cancelado correctamente.'
        )
        case INVOICE_ACTION_CANCEL_ALL:
          return 'La facturaciones fueron canceladas correctamente.'
      default:
        return ''
    }
  }

  showConfirmModal = (e, row, action) => {
    this.setState(
      {
        invoiceAction: action,
        toSendInvoice: { ...row }
      },
      () => {
        this.toggleConfirmModal()
      }
    )
  }

  showConfirmModalAll = (invoices, action) => {
    this.setState(
      {
        invoiceAction: action,
        toSendInvoice: [...invoices]
      },
      () => {
        this.toggleConfirmModal()
      }
    )
  }

  sendInvoice = async () => {
    this.toggleConfirmModal()

    const request = {Facturaciones: Array.concat(this.state.toSendInvoice).map(invoice => ({
      centroFactura: invoice.centroFactura,
      numeroComprobante: invoice.numeroComprobante,
      tipoComprobante: invoice.tipoComprobante,
      tipoFactura: invoice.tipoFactura
    }))}
    this.selectedItems = []
    const [err] = await to(sendInvoice(request))
    if (!err) {
      this.toggleSuccessModal()
      this.handleSubmit()
    }
    else {
      this.handleSubmit()
    }
  }

  cancelInvoiceSending = async () => {
    this.toggleConfirmModal()

    const request = {Facturaciones: Array.concat(this.state.toSendInvoice).map(invoice => ({
      centroFactura: invoice.centroFactura,
      numeroComprobante: invoice.numeroComprobante,
      tipoComprobante: invoice.tipoComprobante,
      tipoFactura: invoice.tipoFactura
    }))}
    this.selectedItems = []
    const [err] = await to(cancelInvoiceSending(request))
    if (!err) {
      this.toggleSuccessModal()
      this.handleSubmit()
    }
    else {
      this.handleSubmit()
    }
  }

  setInitialPage = () => {
    const newState = this.state
    newState.request.pagina = 1
    this.setState(newState)
  }

  getCompanyByCuit = cuit => {
    return this.state.companiesCuits.find(cc => cc.id === cuit)
      ? this.state.companiesCuits.find(cc => cc.id === cuit).valueText
      : ''
  }

  render() {
    //Start of Table Definition
    var columns = [
      { title: 'Financiador', index: 'financiador', size: 'minmax(150px, 1fr)' },
      { title: 'N° Factura', index: 'numeroFactura', size: 'minmax(140px, 1fr)' },
      { title: 'Fecha', index: 'fecha', size: 'minmax(130px, 1fr)' },
      { title: 'Importe', index: 'importeComprobante', size: 'minmax(130px, 1fr)' },
      {
        title: 'Descargada',
        index: 'descargada',
        render: row => <React.Fragment>{row.descargado ? 'Sí' : 'No'}</React.Fragment>,
        size: 'minmax(95px, 110px)'
      },
      {
        title: 'Acciones',
        render: row => {
          return (
            <ActionsContainer>
              {this.props.auth.permisos.some(permiso => permiso.clave === REPORT_PERMIT) &&
                !row.enviado && (
                  <SizedButton
                    green
                    onClick={e => this.showConfirmModal(e, row, INVOICE_ACTION_SEND)}
                  >
                    <MaterialIcon name="send" />
                    Enviar
                  </SizedButton>
                )}

              {this.props.auth.permisos.some(permiso => permiso.clave === REPORT_PERMIT) &&
                row.enviado && (
                  <SizedButton
                    green
                    onClick={e => this.showConfirmModal(e, row, INVOICE_ACTION_CANCEL)}
                    disabled={row.descargado}
                  >
                    <MaterialIcon name="cancel_schedule_send" />
                    Cancelar
                  </SizedButton>
                )}

              <SizedButton green onClick={e => this.downloadInvoicePackage(e, row)} outline>
                <MaterialIcon name="insert_drive_file" />
                Descargar Zip
              </SizedButton>
            </ActionsContainer>
          )
        },
        size: 'minmax(360px, 1fr)'
      },
      {
        title: 'Ver',
        index: 'digitalizado',
        render: row => {
          return (
            <React.Fragment>
              {!!row.digitalizado ? (
                <A
                  to={{
                    pathname: `/inicio/facturacion/${row.numeroFactura}`,
                    data: {
                      ...row,
                      invoicePage: this.state.request.pagina,
                      searchInvoiceInputs: {
                        idClinica: this.state.request.idClinica,
                        cuit: this.state.request.cuit,
                        tipoComprobante: this.state.request.tipoComprobante,
                        tipoFactura: this.state.request.tipoFactura,
                        centroFactura: this.state.request.centroFactura,
                        numeroComprobante: this.state.request.numeroComprobante,
                        fechaDesde: this.state.request.fechaDesde,
                        fechaHasta: this.state.request.fechaHasta,
                        estadoEnvio: this.state.request.estadoEnvio
                      }
                    }
                  }}
                >
                  Ver más
                </A>
              ) : (
                '-'
              )}
            </React.Fragment>
          )
        },
        size: 'minmax(90px, 110px)'
      }
    ]

    if (!this.props.auth.permisos.some(permiso => permiso.clave === REPORT_PERMIT)) {
      columns = columns.filter(c => c.title !== 'Financiador')
    }
    //End of Table Definition

    return (
      <Layout extended notReduce subTitle="Buscar comprobantes de facturación" title="Facturación">
        <FiltersContainer>
          {this.state.request.estadoEnvio && (
            <FormFilterInvoices
              showSentFilter={this.props.auth.permisos.some(
                permiso => permiso.clave === REPORT_PERMIT
              )}
              defaultTipoComprobante={this.state.request.tipoComprobante}
              defaultTipoFactura={this.state.request.tipoFactura}
              defaultCentroFactura={this.state.request.centroFactura}
              defaultNumeroComprobante={this.state.request.numeroComprobante}
              listOfClinics={this.state.listOfClinics}
              clinic={this.state.request.idClinica}
              listOfInstitutions={this.state.listOfInstitutions}
              institution={this.state.institution}
              defaultStatus={this.state.request.estadoEnvio}
              defaultStart={this.state.request.fechaDesde}
              defaultEnd={this.state.request.fechaHasta}
              submitting={this.state.submitting}
              onSubmit={values => {
                this.setInitialPage()
                this.handleSubmit(values)
              }}
            />
          )}
        </FiltersContainer>

        <TableContainer>
          <MobileFullWidth>
            <Table
              green
              columns={columns}
              rows={this.state.request.items}
              totalPages={this.state.request.totalPaginas}
              itemsPerPage={this.state.request.itemsPorPagina}
              onClickPage={this.handlePagination}
              searchedAndNoData={this.state.searchedAndNoData}
              pagina={this.state.request.pagina}
              handleSort={this.handleSort}
              useCheckboxes
              getSelectedItems={items => {
                this.selectedItems = [...items]
              }}
            >
              {this.props.auth.permisos.some(permiso => permiso.clave === REPORT_PERMIT) && (
                <ButtonsContainer>
                  <SizedButton
                    green
                    onClick={() =>
                      this.showConfirmModalAll(this.selectedItems, INVOICE_ACTION_SEND_ALL)
                    }
                  >
                    <MaterialIcon name="send" />
                    Enviar todo
                  </SizedButton>
                  <SizedButton
                    green
                    onClick={() =>
                      this.showConfirmModalAll(this.selectedItems, INVOICE_ACTION_CANCEL_ALL)
                    }
                  >
                    <MaterialIcon name="cancel_schedule_send" />
                    Cancelar todo
                  </SizedButton>
                  <SizedButton
                    outline
                    green
                    onClick={this.downloadInvoiceList}
                    disabled={!this.state.request.items.length}
                  >
                    <MaterialIcon name="get_app" />
                    Exportar Excel
                  </SizedButton>
                </ButtonsContainer>
              )}
            </Table>
          </MobileFullWidth>
        </TableContainer>

        {this.state.showConfirmModal && (
          <ModalConfirmation
            iconCode="help"
            message={this.getConfirmMessage()}
            buttonCancel={{ action: this.toggleConfirmModal, label: 'Cancelar' }}
            buttonConfirm={{ action: this.getActionFunc(), label: 'Aceptar' }}
          />
        )}

        {this.state.showSuccessModal && (
          <ModalMessage
            iconCode="check_circle"
            message={this.getSuccessMessage()}
            button={{ action: this.toggleSuccessModal, label: 'Cerrar' }}
          />
        )}
      </Layout>
    )
  }
}

export default withUser(InvoiceList)

/*
 * Styles
 */
const FiltersContainer = styled.div`
  width: 100%;
  position: relative;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.colors.greyFive};
  min-height: 55px;
  display: block;
  height: auto;
`

const TableContainer = styled.div`
  @media (min-width: ${props => props.theme.sizes.breakPoints.small}) {
    width: 95%;
    margin: auto;
  }
`

const SizedButton = styled(Button)`
  min-width: 140px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-weight: 400;
  font-size: 12px;
  border-radius: 5px;
  i {
    font-size: 16px;
  }
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    width: 142px;
  }
  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
      color: ${props => props.theme.colors.greyTwo};
      &:hover {
        color: ${props => props.theme.colors.greyTwo};
      }
    `};
`

const A = styled(Link)`
  font-weight: 300;
  font-size: 12px;
  color: ${props => props.theme.colors.green};
`

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`

const ActionsContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
`
